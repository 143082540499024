import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription, switchMap } from 'rxjs';
import { ImageCacheService } from 'src/app/services/image-cache.service';
import { IUserNotificationPreference, UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit{
  @ViewChild('drawer') drawer: MatDrawer;
  selectedAction = '';
  selectedFile: File;
  userId: string;
  userProfileUrl: any;

  preferencesform: FormGroup = new FormGroup({
    modified: new FormControl(true),
    reachFinalState: new FormControl(true),
    expiring: new FormControl(true),
    frequency: new FormControl(0)
  });
  profileForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl(''),
  });

  private subscriptions = new Subscription();

  constructor(
    private usersService: UsersService,
    private imageCacheService: ImageCacheService
  ) {}

  ngOnInit() {
    const userSub = this.usersService.getUserProfile()
      .pipe(
        switchMap((profile: any) => {
          this.userId = profile.data.id.toString();
          this.profileForm.setValue({
            firstName: profile.data.firstName,
            lastName: profile.data.lastName,
            phoneNumber: profile.data.phoneNumber ?? '',
          });

          const cachedImage = this.imageCacheService.getImageFile(this.userId);
          if (cachedImage) {
            return this.imageCacheService.convertFileToDataUrl(cachedImage);
          } else {
            return this.usersService.getUserImageProfile(this.userId);
          }
        })
      )
      .subscribe((res: any) => {
        if (typeof res === 'string') { // from Cache
          this.userProfileUrl = res;
        } else if (res.data.profileImage) {
          const imageFile = this.imageCacheService.base64ToFile(res.data.profileImage);
          this.imageCacheService.cacheImage(this.userId, imageFile);
          this.imageCacheService.convertFileToDataUrl(imageFile).subscribe(url => this.userProfileUrl = url);
        }
      });

    this.subscriptions.add(userSub);

    this.loadPreferences();
  }

  onOpenPreferences() {
    this.selectedAction = 'preferences';
    this.drawer.toggle();
  }
  onOpenProfile() {
    this.selectedAction = 'profile';
    this.drawer.toggle();
  }

  updatePreferences() {
    let data = this.preferencesform.value;
    const preferences: IUserNotificationPreference = {
      events: [
        {
          event: 'Modified',
          isActive: data.modified
        },
        {
          event: 'ReachedFinalState',
          isActive: data.reachFinalState
        },
        {
          event: 'Expiring',
          isActive: data.expiring
        }
      ],
      frequency: data.frequency
    }
    this.usersService.changeUserPreferences(preferences).subscribe(_ => {
      this.drawer.close();
      this.loadPreferences();
    })
  }

  onImageSelected(file: File) {
    this.selectedFile = file;
  }

  updateProfile() {
    let data = this.profileForm.value;
    data.profileImage = this.selectedFile;

    this.usersService.updateUserProfile(data).subscribe(_=> {

      if(data.profileImage) {
        this.imageCacheService.cacheImage(this.userId, this.selectedFile)
        const reader = new FileReader();
        reader.onload = (e) => this.userProfileUrl = reader.result;
        reader.readAsDataURL(this.selectedFile);
      }

    })

    this.drawer.close();
  }

  close() {
    this.drawer.close();
  }

  private loadPreferences() {
    this.usersService.getPreferences().subscribe(response => {
      if (!response.isError) {
        const modified = response.data.events?.find(ev => ev.event == 'Modified')?.isActive;
        const finalState = response.data.events?.find(ev => ev.event == 'ReachedFinalState')?.isActive;
        const expiring = response.data.events?.find(ev => ev.event == 'Expiring')?.isActive;

        this.preferencesform.setValue({
          modified: modified,
          reachFinalState: finalState,
          expiring: expiring,
          frequency: response.data.frequency
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
