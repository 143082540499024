<mat-drawer-container hasBackdrop>
  <mat-drawer autoFocus="false" #drawer mode="over" position="end">
    <ng-container *ngIf="selectedAction == 'preferences'">
      <app-right-sidebar-container
        [title]="'Notification Preferences'"
        (cancel)="close()"
        (submit)="updatePreferences()"
      >
        <form [formGroup]="preferencesform" class="notification-panel">
          <div class="form-preferences">
            <h6>Customize your notification preferences</h6>
            <div class="form-item">
              <div class="form-text">
                <span>Final state</span>
                <p>
                  Receive a notification when your form has been approved or
                  rejected.
                </p>
              </div>
              <mat-slide-toggle
                [color]="'primary'"
                formControlName="reachFinalState"
              ></mat-slide-toggle>
            </div>
            <div class="form-item">
              <div class="form-text">
                <span>Modifications and comments</span>
                <p>
                  Receive notifications when there are replies to your forms or
                  whether they were modified
                </p>
              </div>
              <mat-slide-toggle
                [color]="'primary'"
                formControlName="modified"
              ></mat-slide-toggle>
            </div>
            <div class="form-item">
              <div class="form-text">
                <span>Document expiring date</span>
                <p>
                  Receive notifications when there are documents about to expire
                </p>
              </div>
              <mat-slide-toggle
                [color]="'primary'"
                formControlName="expiring"
              ></mat-slide-toggle>
            </div>
            <h6 class="mt-32">Customize your emails notification frequency</h6>
            <div class="custom-form-field">
              <mat-radio-group
                class="column-options"
                color="primary"
                formControlName="frequency"
              >
                <mat-radio-button [value]="'OnDemand'" class="form-item">
                  <div class="form-text">
                    <span>Per Event</span>
                    <p>Get an email for each event</p>
                  </div>
                </mat-radio-button>
                <mat-radio-button [value]="'EndOfDay'" class="form-item">
                  <div class="form-text">
                    <span>Daily Summary</span>
                    <p>Receive one email at the end of each day</p>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </form>
      </app-right-sidebar-container>
    </ng-container>
    <ng-container *ngIf="selectedAction == 'profile'">
      <app-right-sidebar-container
        [title]="'Edit Profile'"
        (cancel)="close()"
        (submit)="updateProfile()"
      >
        <form class="profile-panel" [formGroup]="profileForm">
          <div class="form-profile">
            <app-profile-image-upload [initialImage]="userProfileUrl" (imageSelect)="onImageSelected($event)"></app-profile-image-upload>
          </div>
          <div class="custom-form-field">
            <label for="firstName">First Name<span class="required">*</span></label>
            <mat-form-field appearance="outline" class="custom-field">
              <input formControlName="firstName" matInput name="firstName" maxlength="20"/>
              <mat-error *ngIf="profileForm.controls['firstName'].errors?.['required']">
                Enter First Name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="custom-form-field">
            <label for="lastName">Last Name<span class="required">*</span></label>
            <mat-form-field appearance="outline" class="custom-field">
              <input formControlName="lastName" matInput name="lastName" maxlength="20"/>
              <mat-error *ngIf="profileForm.controls['lastName'].errors?.['required']">
                Enter Last Name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="custom-form-field">
            <label for="phoneNumber">Phone Number</label>
            <mat-form-field appearance="outline" class="custom-field">
              <input formControlName="phoneNumber" matInput name="phoneNumber"/>
            </mat-form-field>
          </div>
        </form>
      </app-right-sidebar-container>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content>
    <main>
      <app-main-navigation
        [imageProfile]="userProfileUrl"
        (openPreferences)="onOpenPreferences()"
        (openProfile)="onOpenProfile()"
      ></app-main-navigation>
      <div class="left-sidebar" app-left-sidebar></div>
      <div class="main-container">
        <section class="main-section">
          <router-outlet></router-outlet>
        </section>
      </div>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
